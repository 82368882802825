<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p
        class="title"
        v-if="isTiled">Details</p>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Title</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-model="entity.title"
                  @change="entity.isDirty = true">
                  <option
                    v-for="(a, b) in titles"
                    :value="a"
                    :key="a">{{ b }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">First Name</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <input
                ref="firstName"
                class="input is-capitalized"
                type="text"
                v-model="entity.firstName"
                v-fq-validate="$entity.firstName"
                v-focus-inserted>
            </p>
            <span
              class="help is-danger"
              v-if="!$entity.firstName.required">
              First name is required
            </span>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Last Name</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <input
                class="input is-capitalized"
                type="text"
                v-model="entity.lastName"
                v-fq-validate="$entity.lastName">
            </p>
            <span
              class="help is-danger"
              v-if="!$entity.lastName.required">
              Last name is required
            </span>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Contact Type</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-model="entity.contactType"
                  @change="entity.isDirty = true">
                  <option
                    v-for="(a, b) in contactTypes"
                    :value="b"
                    :key="b">{{ a }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal" />
        <div class="field-body">
          <div class="buttons has-addons">
            <span
              class="button"
              :class="{ 'is-success is-selected' : entity.isActive }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span
              class="button"
              :class="{ 'is-danger is-selected' : !entity.isActive }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import Titles from '@/enums/Titles'
import ContactTypes from '@/enums/ContactTypes'
import { InputValidationDirective } from '@/components/directives'
import { FocusInserted } from '@/components/directives'

export default {
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {},
  mixins: [InputValidationDirective],
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  computed: {
    titles: function() {
      return Titles
    },
    contactTypes: function() {
      return ContactTypes
    },
    $v: function() {
      return this.v ? this.v : this.$vv
    },
    $entity: function() {
      if (this.v) {
        return this.v.contact
      } else {
        return this.$vv.entity
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.$refs.firstName.focus()
    // }, 500)
    this.$vv.detailGroup.$touch()
  },
  methods: {
    toggleActive(active) {
      // this.entity.isDirty = true
      this.entity.isActive = active
      this.$emit('toggleActive')
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Contact'

    // if (this.$v.detailGroup.$error && !this.entity.isNew) {
    //   this.$router.replace(from.path)
    //   this.$notification.openNotificationWithType('danger', title, message)
    // } else {
    //   next()
    // }
  }
}
</script>
