<template>
  <card-modal 
    :visible="visible" 
    :title="'Recurring Remarks'" 
    @ok="ok" 
    @close="close" 
    @cancel="cancel">
    <div 
      class="content" 
      style="height: 10em; overflow: hidden">
      <div class="field">
        <p class="control is-expanded">
          <!-- <v-select ref="searcher" :options="options" :on-search="updateKeyword" max-height="7.5em" label="value" placeholder="Search Recurring Remarks..." :onChange="select">
            <span slot="no-options">
              {{ notFoundMessage }}
            </span>
          </v-select> -->
          <bulma-autocomplete 
            id="searcher" 
            ref="searcher" 
            v-model="searchValue" 
            :data="options" 
            @input="search" 
            @select="select" 
            :loading="isLoading" />
          <span class="help is-info">{{ message }}</span>
        </p>
      </div>
    </div>
  </card-modal>
</template>

<script>
import RecurringRemarkService from './RecurringRemarkService'
import { CardModal } from '@/components/BulmaModal'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import _debounce from 'lodash.debounce'

export default {
  components: {
    CardModal,
    BulmaAutocomplete
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      searchValue: '',
      selectedValue: '',
      options: [],
      isLoading: false,
      isNew: false
    }
  },
  computed: {
    notFoundMessage: function() {
      if (this.searchValue === '') {
        return 'No results'
      } else {
        return 'Remark not found. You can save this remark with the save button.'
      }
    },
    message: function() {
      if (this.searchValue === '') {
        return 'Start typing to search for a remark'
      } else if (this.isNew) {
        return 'Save and use this remark'
      } else {
        return 'Use this remark'
      }
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('searcher').focus()
    }, 500)
  },
  methods: {
    close() {
      // If this is not here
      // Dialog will not show up if [x] or outside of modal is clicked
      this.visible = false
    },
    ok() {
      if (this.searchValue) {
        if (this.isNew) {
          RecurringRemarkService.postRecurringRemark(this.searchValue)
        }
        this.$emit('ok', this.searchValue)
        this.visible = false
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.visible = false
    },

    search: _debounce(async function(key) {
      console.log(`search ${key} - ${this.selectedValue}`)
      if (key === this.selectedValue) {
        return
      }
      this.isNew = true
      this.isLoading = true
      const res = await RecurringRemarkService.getRecurringRemark(key)
      this.options = res.data.result.map(d => d.remarkDescription)
      this.isLoading = false
    }, 500),
    select(val) {
      if (val) {
        this.isNew = false
        this.selectedValue = val
      }
    }
  }
}
</script>
