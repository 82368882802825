<template>
  <address-view
    :addresses="entity.addresses"
    :v="$entity.addresses"
    :is-tiled="isTiled" />
</template>

<script>
import { AddressView } from '@/components/Address'

export default {
  inject: ['$vv'],
  name: 'ContactAddress',
  components: {
    AddressView
  },
  props: {
    entity: null,
    v: null,
    isTiled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    $v: function() {
      return this.v ? this.v : this.$vv
    },
    $entity: function() {
      if (this.$v.entity) {
        return this.$v.entity
      } else {
        return this.$v
      }
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    next()
    // this.$vv.addressesGroup.$touch()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Contact address'
    // // this.invalids = this.getVuelidationErrors()
    // if (this.$v.addressesGroup.$error && !this.entity.isNew) {
    //   this.$router.replace(from.path)
    //   this.$notification.openNotificationWithType('danger', title, message)
    // } else {
    //   next()
    // }
  },
  mounted() {
    // setTimeout(() => {
    //   this.$refs.firstName.focus()
    // }, 500)
    // Line below don't make sense
    // It's main entity's address, not the contact
    // this.$vv.addressesGroup.$touch()
  }
}
</script>
