<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p
        class="title"
        v-if="isTiled">Remarks</p>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Contents</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <textarea
                class="textarea"
                v-model="remarks.remarks"
                @change="remarks.isDirty = true" />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal" />
        <div class="field-body">
          <p class="control">
            <a
              class="button is-primary"
              @click="showRecurringRemark">
              <span class="icon is-small">
                <i class="mdi mdi-repeat" />
              </span>
              <span> Recurring Remarks</span>
            </a>
          </p>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Alert?</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <!-- <bulma-switch type="primary" v-model="entity.remarks.isAlert"></bulma-switch> -->
              <input
                id="alert"
                name="alert"
                class="switch is-rounded"
                type="checkbox"
                v-model="entity.remarks.isAlert">
              <label for="alert" />
              <!-- <label class="radio">
                <input type="radio" name="member" v-model="remarks.isAlert" value="true" @change="remarks.isDirty = true"> Yes
              </label>
              <label class="radio">
                <input type="radio" name="member" v-model="remarks.isAlert" value="false" @change="remarks.isDirty = true"> No
              </label> -->
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import Vue from 'vue'
import RecurringRemarkModal from '@/views/recurringremarks/RecurringRemarkModal'

const RecurringRemarkComponent = Vue.extend(RecurringRemarkModal)

export default {
  components: {},
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardModal: null
    }
  },
  computed: {
    remarks: function() {
      if (this.entity.remarks) {
        return this.entity.remarks
      } else {
        return {}
      }
    }
  },
  methods: {
    showRecurringRemark() {
      this.cardModal = new RecurringRemarkComponent({
        el: document.createElement('div'),
        propsData: {
          visible: false
        }
      }).$on('ok', res => {
        this.remarks.remarks = res + '\n' + this.remarks.remarks
        this.remarks.isDirty = true
      })

      this.cardModal.visible = true
    }
  }
}
</script>
